import { yupResolver } from "@hookform/resolvers/yup";
import { Button, SelectItem, useDisclosure } from "@nextui-org/react";
import {
  useCreate,
  useCustom,
  useGo,
  useInvalidate,
  useList,
  useNotification,
  useOne,
  useUpdate,
} from "@refinedev/core";
import { DatePicker } from "nextui-date-picker";
import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { Asserts } from "yup";
import * as yup from "yup";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import { useFileEntityUrl } from "../../../hooks";
import { ApiSdk } from "../../../lib/api-sdk";
import { AssociationFeatureFlagEnum } from "../../../lib/association-feature-flag.enum";
import { hasAssociationFeatureFlag } from "../../../lib/utils";
import { ResourceIdentifier } from "../../../resources";
import { AssociationRole, AssociationUser, User } from "../../../sdk";
import { ImageDropzone } from "../../image-dropzone/image-dropzone";
import { FormDatePicker } from "../../ui/form/form-datepicker";
import { FormInput } from "../../ui/form/form-input";
import { FormSelect } from "../../ui/form/form-select";
import { EmailInput } from "./user-upsert-form-email";
import {
  AssociationGolfClassSelect,
  CertificatesSelect,
  CoachClassSelect,
  GolfClassSelect,
  RoleClassSelect,
} from "./user-upsert-form-selects";

export type UpsertUserFormProps = {
  user?: AssociationUser;
};
export const UserUpsertForm: React.FC<UpsertUserFormProps> = ({ user }) => {
  const activeAssociation = useActiveAssociation();

  const { data: associationUser } = useOne<AssociationUser>({
    resource: ResourceIdentifier.AssociationUser,
    meta: {
      associationId: activeAssociation?.id,
    },
    id: user?.id,
  });

  const { closeDrawer } = useDrawer();

  let [files, setFiles] = React.useState<null | File[]>(null);
  const { mutateAsync: createUser } = useCreate<Partial<User>>({});
  const { mutateAsync: updateUser } = useUpdate<Partial<User>>({});
  const { mutateAsync: createAssociationUser } =
    useCreate<Partial<AssociationUser>>();
  const { mutate: updateAssociationUser } =
    useUpdate<Partial<AssociationUser>>();
  const { data: roles } = useList<AssociationRole>({
    resource: ResourceIdentifier.AssociationRole,
    meta: {
      associationId: activeAssociation?.id,
    },
  });

  const schema = yup.object().shape({
    photo: yup.string().nullable(),
    email: yup.string().email("Must be a valid email").required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    gender: yup.string(),
    country: yup.string(),
    zip: yup.string(),
    address: yup.string(),
    city: yup.string(),
    dateOfBirth: yup.string().nullable(),
    role: yup
      .number()
      .typeError("Role is required")
      .nullable()
      .notOneOf([null], "role is a required field"),
    associationGolfClass: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .optional()
      .nullable(),
    golfClass: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .nullable(),
    coachClass: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .nullable(),
  });

  const [isImageChanged, setIsImageChanged] = useState(false);

  const form = useForm({
    defaultValues: {
      email: "",
      photo: null,
      firstName: "",
      lastName: "",
      country: "",
      zip: "",
      address: "",
      city: "",
      gender: "male",
      dateOfBirth: null,
      role: null,
      associationGolfClass: undefined,
      golfClass: undefined,
      coachClass: undefined,
    } as Asserts<typeof schema>,
    values: {
      email: associationUser?.data?.user?.email || "",
      photo: associationUser?.data?.user?.photoId || null,
      firstName: associationUser?.data?.user?.firstName || "",
      lastName: associationUser?.data?.user?.lastName || "",
      country: associationUser?.data?.user?.country || "",
      zip: associationUser?.data?.user?.zip || "",
      address: associationUser?.data?.user?.address || "",
      city: associationUser?.data?.user?.city || "",
      gender: associationUser?.data?.user?.gender || "male",
      dateOfBirth: (associationUser?.data?.user?.dateOfBirth as any) || null,
      role: associationUser?.data?.associationRole?.id.toString() as any,
      associationGolfClass:
        associationUser?.data?.associationGolfClass?.id.toString() as any,
      golfClass: associationUser?.data?.golfClass?.id.toString() as any,
      coachClass: associationUser?.data?.coachClass?.id.toString() as any,
    },
    resolver: yupResolver(schema),
  });

  const { open } = useNotification();

  const [existingUser, setExistingUser] = useState<null | User>();

  const {
    handleSubmit,
    control,
    formState: { errors, isLoading: isFormLoading },
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  const existingImageUrl = useFileEntityUrl(
    associationUser?.data?.user?.photoId || existingUser?.photoId
  );

  const invalidate = useInvalidate();
  const onSettled = async () => {
    setIsLoading(false);
    await invalidate({
      resource: ResourceIdentifier.AssociationUser,
      invalidates: ["all"],
    });
  };
  useEffect(() => {
    if (existingUser) {
      console.log("Setting values", existingUser);
      form.setValue("email", existingUser.email);
      form.setValue("firstName", existingUser.firstName || "");
      form.setValue("lastName", existingUser.lastName || "");
      form.setValue("country", existingUser.country || "");
      form.setValue("zip", existingUser.zip || "");
      form.setValue("address", existingUser.address || "");
      form.setValue("city", existingUser.city || "");
      form.setValue("dateOfBirth", existingUser.dateOfBirth || "");
      form.setValue("gender", existingUser?.gender || "");

      form.setValue("photo", existingUser?.photoId);
      form.clearErrors("email");
    }
  }, [existingUser]);

  const upsertAssociationUser = async (data: yup.InferType<typeof schema>) => {
    console.log({ data });
    setIsLoading(true);
    if (files?.[0] && isImageChanged) {
      console.log("Files are present, uploading", files[0]);
      const fileEntity = await ApiSdk.files.uploadFile({
        formData: {
          file: files[0],
        },
      });
      data.photo = fileEntity?.id;
    }

    const dateOfBirth =
      data.dateOfBirth && (data.dateOfBirth as string) !== ""
        ? (new Date(data?.dateOfBirth) as any)
        : "";

    let dateOfBirthString = "";
    if (dateOfBirth) {
      const month = dateOfBirth.getMonth() + 1;
      let monthString = month;
      if (month < 10) {
        monthString = `0${month}`;
      }
      const day = dateOfBirth.getDate();
      let dayString = day.toString();
      if (day < 10) {
        dayString = `0${day}`;
      }
      dateOfBirthString = `${dateOfBirth.getFullYear()}-${monthString}-${dayString}`;
    }

    const values = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      photo: data.photo,
      gender: data.gender,
      dateOfBirth: dateOfBirthString,
      country: data.country,
      address: data.address,
      zip: data.zip,
      city: data.city,

      roles: [
        {
          id: roles?.data?.find((el) => el.id === data.role)?.hasAdminAccess
            ? 3
            : 2,
        },
      ], // 3 = manager 2 = user
    };

    console.log("Upserting user", values);
    // return;

    if (existingUser) {
      await createUser(
        {
          resource: ResourceIdentifier.AssociationUser,
          successNotification: {
            message: "User created",
            type: "success",
          },
          meta: {
            associationId: activeAssociation?.id,
          },
          values: {
            user: {
              id: existingUser.id,
              ...values,
            },
            association: {
              id: activeAssociation?.id,
            },
            associationRole: data.role,
            associationGolfClass: data.associationGolfClass,
            coachClass: data.coachClass,
            golfClass: data.golfClass,
          },
        },
        {
          onSuccess: async () => {
            await updateUser(
              {
                resource: ResourceIdentifier.User,
                id: existingUser.id,
                values: {
                  id: existingUser.id,
                  ...values,
                },
              },
              {
                onSettled: () => {
                  setIsLoading(false);
                },
                onSuccess: () => {
                  closeDrawer();
                },
                onError: (e) => {
                  console.error(e);
                  open?.({
                    message: "Error while creating user",
                    description: e?.response?.data?.message,
                    type: "error",
                  });
                },
              }
            );
          },
          onError: (e) => {
            console.error(e);
            open?.({
              message: "Error while creating user",
              description: e?.response?.data?.message,
              type: "error",
            });
          },
          onSettled: () => {
            setIsLoading(false);
          },
        }
      );
    } else if (associationUser?.data?.user?.id) {
      await updateUser(
        {
          resource: ResourceIdentifier.AssociationUser,
          meta: {
            associationId: activeAssociation?.id,
          },
          id: associationUser.data.id,
          successNotification: {
            message: "User updated",
            type: "success",
          },
          errorNotification: undefined,
          values: {
            user: {
              id: associationUser?.data.user.id,
              ...values,
            } as User,
            associationRole: data.role,
            associationGolfClass: data.associationGolfClass,
            coachClass: data.coachClass,
            golfClass: data.golfClass,
          },
        },
        {
          onSuccess: () => {
            closeDrawer();
          },
          onError: (e) => {
            console.error(e);
            open?.({
              message: "Error while creating user",
              description: e?.response?.data?.message,
              type: "error",
            });
          },
          onSettled: () => {
            setIsLoading(false);
          },
        }
      );
    } else {
      await createUser(
        {
          resource: ResourceIdentifier.User,
          successNotification: {
            message: "User created",
            type: "success",
          },
          values: {
            ...values,
            photo: { id: data.photo },
            associationRole: data.role,
            coachClass: data.coachClass,
            golfClass: data.golfClass,
          },
        },
        {
          onSuccess: async ({ data: user }) => {
            await createAssociationUser(
              {
                resource: ResourceIdentifier.AssociationUser,
                successNotification: false,
                values: {
                  user: {
                    id: user.id,
                  },
                  associationRole: {
                    id: data.role,
                  },
                  association: {
                    id: activeAssociation?.id,
                  },
                },
              },
              {
                onSuccess: () => {
                  closeDrawer();
                },
                onSettled: () => {
                  setIsLoading(false);
                },
              }
            );
          },
          onError: (e) => {
            console.error(e);
            open?.({
              message: "Error while creating association user",
              description: JSON.stringify(e.response.data.errors),
              type: "error",
            });
          },
          onSettled: () => {
            setIsLoading(false);
          },
        }
      );
    }
  };

  const isHideCoachClass = hasAssociationFeatureFlag({
    association: activeAssociation,
    flagName: AssociationFeatureFlagEnum.HIDE_COACH_CLASS,
  });

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(upsertAssociationUser, (error) => {
            console.error({ error });
          })}
        >
          <div className={"flex flex-col gap-4 mb-6 max-w-lg"}>
            <div>
              <h1 className={"text-2xl mb-5"}>User info</h1>
            </div>
            <ImageDropzone
              files={files}
              existingImageUrl={existingImageUrl}
              setFiles={setFiles}
              onClear={() => {
                form.setValue("photo", null);
              }}
              onImageChanged={() => setIsImageChanged(true)}
              label={"Select the user avatar"}
            />

            <EmailInput
              form={form}
              associationUser={associationUser?.data}
              setExistingUser={setExistingUser}
            />

            <div className={"flex flex-col gap-4 md:flex-row"}>
              <FormInput
                name={"firstName"}
                label={"First name"}
                variant={"bordered"}
                control={control}
              />
              <FormInput
                name={"lastName"}
                label={"Last name"}
                variant={"bordered"}
                control={control}
              />
            </div>

            <FormDatePicker
              min={"1900-01-01"}
              max={Date.now().toString()}
              label={"Date of birth"}
              name={"dateOfBirth"}
              variant={"bordered"}
              control={control}
            />

            <div className={"flex flex-col gap-4 md:flex-row"}>
              <FormSelect
                label={"Gender"}
                // defaultSelectedKeys={["male"]}
                variant={"bordered"}
                name={"gender"}
                control={control}
              >
                <SelectItem key={"male"} value={"male"}>
                  Male
                </SelectItem>
                <SelectItem key={"female"} value={"female"}>
                  Female
                </SelectItem>
              </FormSelect>

              <RoleClassSelect
                control={control}
                associationUser={associationUser?.data}
              />
            </div>

            <div className={"flex flex-col gap-4 md:flex-row"}>
              <FormInput
                name={"country"}
                label={"Country"}
                variant={"bordered"}
                control={control}
              />
              <FormInput
                name={"address"}
                label={"Address"}
                variant={"bordered"}
                control={control}
              />
            </div>
            <div className={"flex flex-col gap-4 md:flex-row"}>
              <FormInput
                name={"city"}
                label={"City"}
                variant={"bordered"}
                control={control}
              />

              <FormInput
                name={"zip"}
                label={"Zip"}
                variant={"bordered"}
                control={control}
              />
            </div>

            <div className={"flex flex-col gap-4 md:flex-row"}>
              <AssociationGolfClassSelect
                control={control}
                associationUser={associationUser?.data}
              />
              <GolfClassSelect
                control={control}
                associationUser={associationUser?.data}
              />
            </div>
            {!isHideCoachClass && (
              <div className={"flex flex-col gap-4 md:flex-row"}>
                <CoachClassSelect
                  associationUser={associationUser?.data}
                  control={control}
                />
              </div>
            )}
            <Button
              size={"lg"}
              color={"secondary"}
              type={"submit"}
              fullWidth={true}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
