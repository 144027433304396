import { Association } from "../sdk";
import { AssociationFeatureFlagEnum } from "./association-feature-flag.enum";

export const getDotNotationPropertyValue = (
  obj: Record<string, any>,
  path: string
) => {
  return path.split(".").reduce((acc: any, part: any) => acc && acc[part], obj);
};

export const hasAssociationFeatureFlag = ({
  association,
  flagName,
}: {
  association?: Association | null;
  flagName: keyof typeof AssociationFeatureFlagEnum;
}) => {
  return association?.featureFlags?.some(
    (el) => el.name === flagName && el.isEnabled
  );
};
